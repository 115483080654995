import ScrollToTop from 'components/helpers/ScrollToTop'
import NntsComponents from 'components/nnts'
import PlansRequestAccess from 'components/request-access/Plans'
import ReportsRequestAccess from 'components/request-access/Reports'
import TerminalPaymentRequestAccess from 'components/request-access/TerminalPayment'
import { SentryRoute as Route } from 'components/sentry'
import config from 'config'
import { useHubSpotConversations } from 'hooks/useHubSpotWidget'
import useTimeout from 'hooks/useTimeout'
import AcceptTerms from 'pages/AcceptTerms'
import Account from 'pages/Account'
import BankDetailsVerification from 'pages/BankDetailsVerification'
import Bill from 'pages/Bill'
import Bills from 'pages/Bills'
import BusinessRejected from 'pages/BusinessRejected'
import DocumentPage from 'pages/Document'
import EmailVerify from 'pages/EmailVerify'
import Lead from 'pages/Lead'
import Leads from 'pages/Leads'
import LinkPayment from 'pages/LinkPayment'
import LinkPayments from 'pages/LinkPayments'
import EnterOTP from 'pages/Login/EnterOTP'
import PasswordLogin from 'pages/Login/PasswordLogin'
import RequestOTP from 'pages/Login/RequestOTP'
import ResetPassword from 'pages/Login/ResetPassword'
import SetNewPassword from 'pages/Login/SetNewPassword'
import MerchantUserRegister from 'pages/MerchantUserRegister'
import NewPlan from 'pages/NewPlan'
import NewPlanV2 from 'pages/NewPlanV2'
import NewSubscription from 'pages/NewSubscription'
import NewSubscriptionV2 from 'pages/NewSubscriptionV2'
import NotFound from 'pages/NotFound'
import PasswordChange from 'pages/PasswordChange'
import Payout from 'pages/Payout'
import Payouts from 'pages/Payouts'
import Plan from 'pages/Plan'
import Plans from 'pages/Plans'
import PlansV2 from 'pages/PlansV2'
import PublicPlansSharing from 'pages/PlansV2/PublicPlansSharing'
import StripeOnboardingCompleted from 'pages/PlansV2/StripeOnboarding/Completed'
import PlanV2 from 'pages/PlanV2'
import PlanV2Edit from 'pages/PlanV2/Edit'
import Reconciliation from 'pages/Reconciliation'
import Refunds from 'pages/Refunds'
import Reports, { isAnyReportEnabled } from 'pages/Reports'
import Settings from 'pages/Settings'
import Approved from 'pages/signup/approved'
import BankDetails from 'pages/signup/bank-details'
import BusinessDetails from 'pages/signup/business-details'
import EmailVerification from 'pages/signup/email-verification'
import ManualReview from 'pages/signup/manual-review'
import OfficerDetails from 'pages/signup/officer-details'
import Register from 'pages/signup/register'
import RiskCheckFailed from 'pages/signup/risk-check-failed'
import StripeRouter from 'pages/Stripe'
import Subscription from 'pages/Subscription'
import SubscriptionTermsAndConditions from 'pages/Subscription/TermsAndConditions'
import SubscriptionV2 from 'pages/SubscriptionV2'
import Support from 'pages/Support'
import Suspended from 'pages/Suspended'
import TerminalPayment from 'pages/TerminalPayment'
import TerminalPayments from 'pages/TerminalPayments'
import Terms from 'pages/Terms'
import Transaction from 'pages/Transaction'
import Transactions from 'pages/Transactions'
import ViewAsset from 'pages/ViewAsset'
import { useEffect } from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'
import NewTransactionRouter from './NewTransactionRouter'
import pageVersion from './pageVersion'
import PrivateRoute from './PrivateRoute'
import FallbackPrivateRoute from './RequestAccessFallback'

function useHubspot() {
  const support = useRouteMatch({
    path: '/support',
  })

  const isHidden = useRouteMatch({
    path: ['/new-transaction'],
  })

  const delaySec = support ? 30 : 10

  const { load, remove } = useHubSpotConversations()

  const didTimeout = useTimeout(delaySec * 1000)

  const {
    data: { token },
  } = useAuth()

  // // Show widget after some time
  useEffect(() => {
    if (!!token && !isHidden && didTimeout) {
      load()
    }
    if (isHidden) {
      remove()
    }
  }, [didTimeout, isHidden, token, load, remove])
}

const Component = () => {
  const {
    data: { token },
  } = useAuth()
  const { data } = useMerchant(undefined, {
    isEnabled: !!token,
  })
  useHubspot()

  const isPlanV2Capable = data?.flags?.is_plan_v2_capable

  const { flags } = data || {}

  const shouldShowTerminalPayments =
    flags?.is_terminal_payment_capable || flags?.are_terminal_payments_visible

  const shouldShowPlans =
    flags?.is_subscription_plan_capable || flags?.are_subscription_plans_visible

  const shouldShowReports = isAnyReportEnabled(flags)

  return (
    <ScrollToTop>
      <Switch>
        {config.ENV !== 'production' && (
          <Route path="/nnts-components" component={NntsComponents} />
        )}

        <Redirect from="/" to="/transactions" exact />

        <PrivateRoute
          path="/new-transaction/:stepId?"
          component={NewTransactionRouter}
        />
        <PrivateRoute
          path="/new-subscription"
          component={pageVersion(
            NewSubscription,
            NewSubscriptionV2,
            isPlanV2Capable
          )}
        />

        <PrivateRoute
          path="/terminal-payments/:terminalPaymentId"
          component={TerminalPayment}
        />
        <FallbackPrivateRoute
          path="/terminal-payments"
          component={TerminalPayments}
          shouldFallback={!shouldShowTerminalPayments}
          fallback={<TerminalPaymentRequestAccess />}
        />

        <PrivateRoute
          path="/link-payments/:linkPaymentId"
          component={LinkPayment}
        />
        <PrivateRoute path="/link-payments" component={LinkPayments} />

        <PrivateRoute path="/refunds" component={Refunds} />

        <PrivateRoute path="/reconciliation" component={Reconciliation} />

        <PrivateRoute
          path="/transactions/:transactionId"
          component={Transaction}
        />

        <PrivateRoute path="/transactions" component={Transactions} />

        <PrivateRoute path="/bills/:billId" component={Bill} />
        <PrivateRoute path="/bills" component={Bills} />

        <PrivateRoute path="/leads/:leadId" component={Lead} />
        <PrivateRoute path="/leads" component={Leads} />

        <PrivateRoute
          path="/new-plan"
          component={pageVersion(NewPlan, NewPlanV2, isPlanV2Capable)}
        />
        <PrivateRoute
          path="/subscriptions/:subscriptionId/terms"
          component={SubscriptionTermsAndConditions}
        />
        <PrivateRoute
          path="/subscriptions/:subscriptionId"
          component={pageVersion(Subscription, SubscriptionV2, isPlanV2Capable)}
        />
        <PrivateRoute
          path="/plans/stripe-onboarding-completed"
          component={pageVersion(
            null,
            StripeOnboardingCompleted,
            isPlanV2Capable
          )}
        />
        <PrivateRoute
          path="/plans/sharing"
          component={pageVersion(null, PublicPlansSharing, isPlanV2Capable)}
        />
        <PrivateRoute
          path="/plans/:planId/edit"
          component={pageVersion(null, PlanV2Edit, isPlanV2Capable)}
        />
        <PrivateRoute
          path="/plans/:planId"
          component={pageVersion(Plan, PlanV2, isPlanV2Capable)}
        />

        {/* {data === undefined && ( */}
        <FallbackPrivateRoute
          path="/plans"
          component={pageVersion(Plans, PlansV2, isPlanV2Capable)}
          shouldFallback={!shouldShowPlans}
          fallback={<PlansRequestAccess />}
        />
        {/* )} */}

        <PrivateRoute path="/payouts/:payoutId" component={Payout} />
        <PrivateRoute path="/payouts" component={Payouts} />

        <PrivateRoute path="/stripe" component={StripeRouter} />

        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute path="/settings" component={Settings} />
        <FallbackPrivateRoute
          path="/reports"
          component={Reports}
          shouldFallback={!shouldShowReports}
          fallback={<ReportsRequestAccess />}
        />
        <PrivateRoute
          path="/bank-verification"
          component={BankDetailsVerification}
        />
        <Route path="/not-supported" component={BusinessRejected} />
        <Route path="/suspended" component={Suspended} />
        <Route
          path="/merchant-user-registration/:token"
          component={MerchantUserRegister}
        />
        <PrivateRoute path="/password-change" component={PasswordChange} />
        <Route path="/email-verification/:token?" component={EmailVerify} />

        {/* Legacy */}
        <Redirect
          from="/password-reset/:token"
          to="/auth/reset-password/:token"
        />
        <Redirect from="/password-reset" to="/auth/reset-password" />
        <Redirect from="/login" to="/auth/request-otp" />

        {/* New auth flow */}
        <Route path="/auth/request-otp" component={RequestOTP} />
        <Route path="/auth/enter-otp" component={EnterOTP} />
        <Route path="/auth/password-login" component={PasswordLogin} />
        <Route
          path="/auth/reset-password/:passwordToken"
          component={SetNewPassword}
        />
        <Route path="/auth/reset-password" component={ResetPassword} />

        <Route path="/support" component={Support} />
        <PrivateRoute path="/terms" component={Terms} />
        <PrivateRoute path="/documents/:documentId" component={DocumentPage} />
        <PrivateRoute path="/accept-terms" component={AcceptTerms} />
        <PrivateRoute path="/view-asset" component={ViewAsset} />

        <Route path="/signup/register" component={Register} />
        <Route
          path="/signup/email-verification"
          component={EmailVerification}
        />
        <Route path="/signup/business-details" component={BusinessDetails} />
        <Route path="/signup/officer-details" component={OfficerDetails} />
        <Route path="/signup/bank-details" component={BankDetails} />
        <Route path="/signup/approved" component={Approved} />
        <Route path="/signup/manual-review" component={ManualReview} />
        <Route path="/signup/risk-check-failed" component={RiskCheckFailed} />

        <Redirect from="/signup" to="/signup/register" exact />

        <Route component={NotFound} />
      </Switch>
    </ScrollToTop>
  )
}

export default Component
