import PrivateRoute from './PrivateRoute'

type FallbackPrivateRouteProps = React.ComponentProps<typeof PrivateRoute> & {
  fallback: React.ReactNode
  shouldFallback?: boolean
}

function FallbackPrivateRoute({
  fallback,
  shouldFallback,
  ...props
}: FallbackPrivateRouteProps) {
  if (shouldFallback) {
    return fallback
  }

  return <PrivateRoute {...props} />
}

export default FallbackPrivateRoute
