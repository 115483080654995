import { Button, Caption, Flex, Icons, View } from '@tabeo/scarf'
import useCountry from 'hooks/useCountry'
import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'
import { ReactComponent as AccountIcon } from './assets/navigation-menu-account.svg'
import { ReactComponent as BillsIcon } from './assets/navigation-menu-bills.svg'
import { ReactComponent as FinanceIcon } from './assets/navigation-menu-finance.svg'
import { ReactComponent as LeadsIcon } from './assets/navigation-menu-leads.svg'
import { ReactComponent as LinksIcon } from './assets/navigation-menu-links.svg'
import { ReactComponent as PayoutsIcon } from './assets/navigation-menu-payouts.svg'
import { ReactComponent as ReconciliationIcon } from './assets/navigation-menu-reconciliation.svg'
import { ReactComponent as RefundsIcon } from './assets/navigation-menu-refunds.svg'
import { ReactComponent as ReportsIcon } from './assets/navigation-menu-reports.svg'
import { ReactComponent as SettingsIcon } from './assets/navigation-menu-settings.svg'
import { ReactComponent as SignOutIcon } from './assets/navigation-menu-sign-out.svg'
import { ReactComponent as SubscriptionsIcon } from './assets/navigation-menu-subscriptions.svg'
import { ReactComponent as SuportIcon } from './assets/navigation-menu-support.svg'
import { ReactComponent as TerminalPaymentsIcon } from './assets/navigation-menu-terminal.svg'
import { ReactComponent as TransactionsIcon } from './assets/navigation-menu-transactions.svg'
import tabeoIcon from './assets/tabeo-icon.svg'

function Sidebar(props: ComponentPropsWithoutRef<typeof Flex>) {
  const {
    data: { user },
    resource,
  } = useAuth()
  const { logout } = resource || {}
  const { data } = useMerchant()
  const country = useCountry()
  const { isLeadGenerationEnabled, metadata, hasHSBCnet, flags } = data || {}
  const merchantLogo = metadata?.merchantTheme?.logo
  const { t } = useTranslation()
  const shouldShowTerminalPaymentsItems =
    flags?.is_terminal_payment_capable || flags?.are_terminal_payments_visible
  const shouldShowLinkPaymentsItems =
    flags?.is_link_payment_capable || flags?.are_link_payments_visible
  const isLPorTPEnabled =
    shouldShowLinkPaymentsItems || shouldShowTerminalPaymentsItems

  const shouldShowBills = flags?.are_bills_visible || flags?.is_bills_capable

  const isNewTransactionScreen = useRouteMatch({
    path: '/new-transaction',
  })

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      bg="white"
      boxShadow={2}
      flexShrink="0"
      borderRight={1}
      data-testid="sidebar"
      {...props}
    >
      <View flex="1">
        <View p={4}>
          {merchantLogo ? (
            <View
              as="img"
              src={merchantLogo}
              maxHeight="50px"
              maxWidth="100%"
              mb={4}
              display={['none', 'flex']}
            />
          ) : (
            <Flex mb={4} display={['none', 'flex']}>
              <View as="img" src={tabeoIcon} mr={2} flexShrink="0" />
              <Caption color="ink.0" fontWeight="semibold" flex="1">
                {data?.tradingName}
              </Caption>
            </Flex>
          )}
          <Button
            leftIcon={p => <Icons.Plus light {...p} />}
            width="100%"
            as={Link}
            to="/new-transaction"
            variant={isNewTransactionScreen ? 'secondary' : 'primary'}
          >
            {t('New transaction')}
          </Button>
        </View>
        <View display="grid" gridGap={1} gridTemplateColumns="1fr">
          <NavItem
            to="/transactions"
            icon={isLPorTPEnabled ? FinanceIcon : TransactionsIcon}
          >
            {isLPorTPEnabled ? t('Finance') : t('Transactions')}
          </NavItem>

          <NavItem to="/terminal-payments" icon={TerminalPaymentsIcon}>
            {t('Terminal Payments')}
          </NavItem>

          {shouldShowLinkPaymentsItems && (
            <NavItem to="/link-payments" icon={LinksIcon}>
              {t('Payment Links')}
            </NavItem>
          )}
          {isLPorTPEnabled && (
            <NavItem to="/refunds" icon={RefundsIcon}>
              {t('Refunds')}
            </NavItem>
          )}

          <NavItem to="/plans" icon={SubscriptionsIcon}>
            {!flags?.is_plan_v2_capable &&
            (flags?.are_subscription_plans_visible ||
              flags?.is_subscription_plan_capable)
              ? t('Subscriptions')
              : t('Plans')}
          </NavItem>

          {country.isUK && (
            <>
              {shouldShowBills && (
                <NavItem to="/bills" icon={BillsIcon}>
                  {t('Bills')}
                </NavItem>
              )}
              {isLeadGenerationEnabled && (
                <NavItem to="/leads" icon={LeadsIcon}>
                  {t('Leads')}
                </NavItem>
              )}
              {hasHSBCnet && (
                <NavItem to="/payouts" icon={PayoutsIcon}>
                  {t('Payouts')}
                </NavItem>
              )}
            </>
          )}
          {isLPorTPEnabled && (
            <NavItem to="/reconciliation" icon={ReconciliationIcon}>
              {t('Reconciliation')}
            </NavItem>
          )}

          <NavItem to="/reports" icon={ReportsIcon}>
            {t('Reports')}
          </NavItem>
        </View>
      </View>
      <View flexShrink="0" borderTop={1} py={4}>
        {user && (
          <NavItem icon={AccountIcon} to="/account">
            {user.firstName} {user.lastName}
          </NavItem>
        )}
        <NavItem to="/settings" icon={SettingsIcon} ug-id="settings-nav-item">
          {t('Settings')}
        </NavItem>
        <NavItem to="/support" icon={SuportIcon}>
          {t('Support')}
        </NavItem>
        <NavItem icon={SignOutIcon} onClick={logout}>
          {t('Sign out')}
        </NavItem>
      </View>
    </Flex>
  )
}

export default Sidebar

function NavItem({ children, icon, to, ...rest }: any) {
  const match = useRouteMatch(to)
  const isActive = !!to && !!match
  return (
    <Flex
      role="group"
      justifyContent="flex-start"
      px={4}
      py={2}
      position="relative"
      bg={isActive && 'sky.3'}
      _hover={{
        bg: 'sky.2',
      }}
      _active={{
        bg: 'sky.2',
      }}
      cursor="pointer"
      as={to ? Link : undefined}
      to={to || undefined}
      {...rest}
    >
      {isActive && (
        <View
          position="absolute"
          left="0"
          width="3px"
          height="100%"
          bg="primary.3"
        />
      )}
      {icon && (
        <View
          as={icon}
          mr={2}
          width={['24px', '20px']}
          height={['24px', '20px']}
          color={isActive ? 'primary.3' : 'ink.2'}
          _groupHover={{
            color: isActive ? 'primary.3' : 'ink.0',
          }}
        />
      )}
      <Caption
        color={isActive ? 'primary.3' : 'ink.0'}
        fontWeight={isActive ? 'semibold' : 'regular'}
        fontSize={['16px', '14px']}
      >
        {children}
      </Caption>
    </Flex>
  )
}
