import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { Title } from '@tabeo/scarf'
import { MerchantFlags } from '@tabeo/ts-types'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'

interface Report {
  id: string
  title: string
  href: string
  flagKey?: keyof MerchantFlags
}

const REPORTS_CONFIG: Array<{
  id: string
  title: string
  flagKey?: keyof MerchantFlags
  reports: Report[]
}> = [
  {
    id: 'finance',
    title: 'Finance',
    reports: [
      {
        id: 'cash-reconciliation',
        title: 'Cash Reconciliation',
        href: 'https://lookerstudio.google.com/u/0/reporting/858b136c-6054-40e3-8f46-5bc14926c0de/page/p_ox2c8n6xhd',
        flagKey: 'is_report_finance_enabled',
      },
      {
        id: 'cash-reconciliation-in-house',
        title: 'Cash Reconciliation (In-house)',
        href: 'https://lookerstudio.google.com/u/0/reporting/ad7ea503-7d40-4199-8118-8e61fda9f294/page/p_ox2c8n6xhd',
        flagKey: 'is_report_finance_in_house_enabled',
      },
      {
        id: 'performance',
        title: 'Performance',
        href: 'https://lookerstudio.google.com/u/0/reporting/a5b0da0a-cb2e-4b55-8682-80c43f2ca228/page/p_ox2c8n6xhd',
      },
      // Not yet supported by Data team
      // {
      //   id: 'clinicians',
      //   title: 'Clinician Activity',
      //   href: '/reports/finance/clinicians',
      // },
    ],
  },
  {
    id: 'cards',
    title: 'Cards',
    flagKey: 'is_report_cards_enabled',
    reports: [
      {
        id: 'cash-reconciliation',
        title: 'Cash Reconciliation',
        href: 'https://lookerstudio.google.com/reporting/72d3d41e-c4a3-4a30-a1c9-dfd2c78bb28f/page/p_ox2c8n6xhd',
      },
      {
        id: 'performance',
        title: 'Performance',
        href: 'https://lookerstudio.google.com/u/0/reporting/ba2be08c-ac4f-4a8c-8dc5-0ae07da3dddb/page/p_ox2c8n6xhd',
      },
    ],
  },
  {
    id: 'plans',
    title: 'Plans',
    flagKey: 'is_report_plans_enabled',
    reports: [
      {
        id: 'cash-reconciliation',
        title: 'Cash Reconciliation',
        href: '/reports/plans/cash-reconciliation',
      },
      {
        id: 'performance',
        title: 'Performance',
        href: '/reports/plans/performance',
      },
      {
        id: 'clinicians',
        title: 'Clinicians',
        href: '/reports/plans/clinicians',
      },
    ],
  },
]

interface ReportCardProps {
  title: string

  href: string
}

function ReportCard({ title, href }: ReportCardProps) {
  const { t } = useTranslation()

  return (
    <div className="caption flex justify-between gap-4 border-b border-tabeo-sky-1 py-4">
      <div className="text-tabeo-ink-2">{title}</div>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-1"
      >
        <span className="anchor">{t('View report')}</span>
        <ArrowTopRightOnSquareIcon className="h-5 w-5 text-tabeo-primary-3" />
      </a>
    </div>
  )
}

interface ReportSectionProps {
  title: string
  reports: Report[]
  flags: MerchantFlags | undefined
}

function hasEnabledReports(
  section: (typeof REPORTS_CONFIG)[0],
  flags: MerchantFlags | undefined
) {
  if (!flags) return false

  // If section has a flagKey, check if any report is enabled (considering both section flag and individual report flags)
  if (section.flagKey) {
    const sectionFlagKey = section.flagKey // TypeScript now knows this is defined
    return section.reports.some(report => {
      // If report has its own flag, check that flag
      if (report.flagKey) {
        return flags[report.flagKey]
      }
      // If no individual flag, check section flag
      return flags[sectionFlagKey]
    })
  }

  // If section has no flagKey (like finance), check if any report with a flagKey is enabled
  return section.reports.some(report => report.flagKey && flags[report.flagKey])
}

export function isAnyReportEnabled(flags: MerchantFlags | undefined) {
  return REPORTS_CONFIG.some(section => hasEnabledReports(section, flags))
}

function ReportSection({ title, reports, flags }: ReportSectionProps) {
  if (!flags) return null

  return (
    <div>
      <h2 className="mb-1 font-medium text-tabeo-ink-0">{title}</h2>
      <div className="">
        {reports.map(
          report =>
            (!report.flagKey || flags[report.flagKey]) && (
              <ReportCard key={report.id} {...report} />
            )
        )}
      </div>
    </div>
  )
}

function Reports() {
  const { t } = useTranslation()
  const { data } = useMerchant()

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <Container maxWidth="788px" bg="white">
          <DatoNotifications />
          <Title mb={6}>{t('Reports')}</Title>
          <div className="space-y-10">
            {REPORTS_CONFIG.map(
              section =>
                hasEnabledReports(section, data.flags) && (
                  <ReportSection
                    key={section.id}
                    title={section.title}
                    reports={section.reports}
                    flags={data.flags}
                  />
                )
            )}
          </div>
        </Container>
      )}
    </LayoutSideNavbar>
  )
}

export default Reports
