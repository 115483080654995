import { DataTable, GridTable, Title } from '@tabeo/scarf'
import { State } from '@tabeo/scarf/dist/components/DataTable/context'
import AmountFilter from 'components/AmountFilter'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useTerminalPayments } from 'resources/TerminalPayments'
import ListItem from './ListItem'

function mapStateToParams(state: State) {
  const { activeTabs, activeFilters } = state

  const status = activeTabs?.flatMap(tab => tab.statuses)

  const createdAt = activeFilters?.createdAt?.value?.map(({ value }: any) => {
    let start
    let end

    switch (value) {
      case 'today':
        start = moment().startOf('day')
        end = null
        break
      case 'yesterday':
        start = moment().subtract(1, 'days').startOf('day')
        end = moment().subtract(1, 'days').endOf('day')
        break
      case '3-7d':
        start = moment().subtract(7, 'days')
        end = moment().subtract(2, 'days').endOf('day')
        break
      case '7-14d':
        start = moment().subtract(14, 'days')
        end = moment().subtract(7, 'days')
        break
      case '14-30d':
        start = moment().subtract(30, 'days')
        end = moment().subtract(14, 'days')
        break
      case '30+d':
        start = null
        end = moment().subtract(30, 'days')
        break
      default:
        return null
    }

    return `date_range("${start?.format('YYYY-MM-DD HH:mm:ss') || ''}"-"${
      end?.format('YYYY-MM-DD HH:mm:ss') || ''
    }")`
  })

  const amountRange = activeFilters?.amount?.value
  const amount = amountRange
    ? `range(${amountRange.min || ''}-${amountRange.max || ''})`
    : undefined

  return {
    ...state,
    conditions: {
      status,
      createdAt,
      amount,
    },
  }
}

function TerminalPayments() {
  const { t } = useTranslation()

  const filters = [
    {
      name: 'amount',
      label: 'Amount',
      initialValue: {
        min: '',
        max: '',
      },
      component: AmountFilter,
    },
    {
      name: 'createdAt',
      label: 'Date',
      options: [
        { label: t('Today'), value: 'today' },
        { label: t('Yesterday'), value: 'yesterday' },
        { label: t('3-7 days ago'), value: '3-7d' },
        { label: t('7-14 days ago'), value: '7-14d' },
        { label: t('14-30 days ago'), value: '14-30d' },
        { label: t('More than 30 days ago'), value: '30+d' },
      ],
      initialValue: [],
      selectProps: {
        maxHeight: ['100%', '100%'],
      },
    },
  ]

  const tabs = [
    {
      label: t('All'),
      statuses: ['auth', 'p', 'refund_req', 'fr', 'pr', 's'],
      group: 'singleSelect',
    },
    // Disable auth status for now
    // {
    //   label: t('Authorised'),
    //   statuses: ['auth'],
    //   group: 'groupSelect',
    // },
    {
      label: t('Paid'),
      statuses: ['p'],
      group: 'groupSelect',
    },
    {
      label: t('Settled'),
      statuses: ['s'],
      group: 'groupSelect',
    },
    {
      label: t('Refunds'),
      statuses: ['refund_req', 'fr', 'pr'],
      group: 'groupSelect',
    },
    // {
    //   label: t('Disputes'),
    //   statuses: ['dispute'],
    //   group: 'groupSelect',
    // },
  ]

  return (
    <LayoutSideNavbar>
      <Container maxWidth="992px">
        <DatoNotifications />
        <Title mb={4}>{t('Terminal Payments')}</Title>
        <DataTable
          data-testid="terminal-payments-table"
          mapStateToParams={mapStateToParams}
          // @ts-ignore
          resourceHook={useTerminalPayments}
          tabs={tabs}
          paginationLimit={20}
          searchPlaceholder={t(
            'Search by ID, patient name, email address, phone number, clinician or treatment'
          )}
          filters={filters}
          initialState={{
            orderBy: { field: 'createdAt', direction: 'desc' },
          }}
        >
          {({ items }) => (
            <GridTable
              templateColumns={[
                'minmax(139px,auto) minmax(200px, 1fr) minmax(200px, 1fr) minmax(140px, 1fr) minmax(144px, 1fr) minmax(135px, 1fr)',
              ]}
              minWidth="650px"
            >
              <DataTable.Head
                columns={[
                  { label: 'ID', orderBy: 'id' },
                  { label: 'Patient', orderBy: 'patientName' },
                  { label: 'Treatment' },
                  {
                    label: 'Amount',
                    orderBy: 'amount',
                    cellProps: {
                      justifyContent: 'flex-end',
                    },
                  },
                  {
                    label: 'Status',
                  },
                  {
                    label: 'Date',
                    orderBy: 'createdAt',
                    cellProps: {
                      justifyContent: 'flex-end',
                    },
                  },
                ]}
                // @ts-ignore
                display={['none', 'contents']}
              />
              {items.map(item => (
                <ListItem key={item.id} data={item} />
              ))}
            </GridTable>
          )}
        </DataTable>
      </Container>
    </LayoutSideNavbar>
  )
}

export default TerminalPayments
