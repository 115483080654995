import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import { RequestAccess } from 'components/RequestAccess'
import { useTranslation } from 'react-i18next'
import requestAccessImage from './assets/terminal-payment.png'

function TerminalPaymentRequestAccess() {
  const { t } = useTranslation()
  return (
    <LayoutSideNavbar>
      <Container bg="white" maxWidth="460px">
        <RequestAccess
          image={requestAccessImage}
          title={t('Take in-person payments')}
          description={t(
            'Terminal Payments is a premium feature. Reach out to the Tabeo Sales team to get started!'
          )}
          href="https://calendly.com/tabeo_introduction/tabeo-cards-portal"
        />
      </Container>
    </LayoutSideNavbar>
  )
}

export default TerminalPaymentRequestAccess
