import { Button } from '@tabeo/scarf2'
import BgBlob from 'assets/bg-blob'

import { useTranslation } from 'react-i18next'

export function RequestAccess({
  image,
  title,
  description,
  href,
}: {
  image: string
  title: string
  description: string
  href: string
}) {
  const { t } = useTranslation()
  return (
    <div className="pt-[104px] text-center">
      <div className="relative mx-auto mb-5 h-[220px] w-[280px]">
        <img
          src={image}
          alt={title}
          className="relative z-10 mx-auto h-[220px] w-[200px]"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <BgBlob className="rounded-full text-tabeo-primary-5" />
        </div>
      </div>
      <h2 className="title mb-1">{title}</h2>
      <p className="text-tabeo-ink-1">{description}</p>
      <Button
        className="mt-5"
        as="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('Request access')}
      </Button>
    </div>
  )
}
