import { Container as BaseContainer, Flex, Spinner, View } from '@tabeo/scarf'
import { ThemedLogo } from 'components/Logo'
import { Hamburger } from 'components/Nav/commons'
import Sidebar from 'components/Sidebar'
import { ComponentProps, useEffect, useState } from 'react'
import { useMerchant } from 'resources/Merchant'
import styled from 'styled-components'

export const Container = styled(BaseContainer)``
Container.defaultProps = {
  bg: 'sky.2',
  boxSizing: 'border-box',
  px: [4],
  py: [6],
  minHeight: ['fill-available', '100vh'],
  mx: ['auto'],
  wrapProps: {
    minHeight: ['fill-available', 'auto'],
  },
}

export type LayoutSideNavbarProps = { isLoading?: boolean } & ComponentProps<
  typeof View
>

function LayoutSideNavbar({
  children,
  isLoading,
  ...rest
}: LayoutSideNavbarProps) {
  const [isOpen, toggle] = useState(false)
  useEffect(() => {
    function toggleMenu() {
      toggle(!isOpen)
    }
    if (isOpen) {
      window.addEventListener('click', toggleMenu)
      document.body.style.cursor = 'pointer'
    } else {
      document.body.style.cursor = 'unset'
    }
    return () => window.removeEventListener('click', toggleMenu)
  }, [isOpen, toggle])
  const { data } = useMerchant()
  if (!data) {
    return null
  }
  return (
    <>
      <Flex
        height="40px"
        width="100%"
        px={4}
        bg="white"
        position="fixed"
        top="0"
        left="0"
        zIndex="70"
        boxShadow={isOpen ? 'none' : 1}
        display={['flex', 'none']}
      >
        <ThemedLogo height="24px" />
        <Hamburger
          color="primary.3"
          // @ts-ignore
          active={isOpen}
          onClick={() => toggle(isOpen => !isOpen)}
          width="24px"
        />
      </Flex>
      <View height="100vh" display="flex" overflow="hidden" {...rest}>
        <View
          boxShadow={1}
          height="100vh"
          display={[isOpen ? 'block' : 'none', 'block']}
          position={['fixed', 'sticky']}
          top="0"
          zIndex="20"
          width={['100%', '204px', '260px']}
        >
          {/** @ts-ignore */}
          <Sidebar pt={[10, 0]} overflow="auto" height="100vh" />
        </View>
        <View
          overflow="auto"
          flex="1"
          pt={[isOpen ? 0 : 9, 0]}
          bg="sky.3"
          id="layout-content"
        >
          {isLoading ? <Spinner minHeight="calc(100vh - 60px)" /> : children}
        </View>
      </View>
    </>
  )
}

export default LayoutSideNavbar
