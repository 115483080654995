import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import { RequestAccess } from 'components/RequestAccess'
import { useTranslation } from 'react-i18next'
import requestAccessImage from './assets/reports.png'

function ReportsRequestAccess() {
  const { t } = useTranslation()
  return (
    <LayoutSideNavbar>
      <Container bg="white" maxWidth="390px">
        <RequestAccess
          image={requestAccessImage}
          title={t('Unlock reporting insight')}
          description={t(
            'Reporting is a premium feature. Reach out to your Tabeo Account Manager to get started!'
          )}
          href="https://calendly.com/d/cm3z-hn7-69d/merchant-reporting"
        />
      </Container>
    </LayoutSideNavbar>
  )
}

export default ReportsRequestAccess
