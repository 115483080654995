import { DataTable, GridTable, Title } from '@tabeo/scarf'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import Notifications from 'components/Notifications'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { useLeads } from 'resources/Leads'
import { useMerchant } from '../../resources/Merchant'
import ListItem from './ListItem'

function Leads() {
  const { data: merchant } = useMerchant()
  const { t } = useTranslation()
  // eslint-disable-next-line camelcase
  const isLeadGenerationEnabled = merchant?.flags?.is_lead_generation_enabled

  if (!isLeadGenerationEnabled) {
    return <Redirect to="/" />
  }

  const tabs = [
    {
      label: t('All'),
      statuses: ['open', 'qualified', 'succeeded', 'failed'],
      group: 'singleSelect',
    },
    { label: t('Open'), statuses: ['qualified'] },
    { label: t('Won'), statuses: ['succeeded'] },
    { label: t('Lost'), statuses: ['failed'] },
  ]

  return (
    <LayoutSideNavbar>
      <Container>
        <Notifications mb={6} />
        <Title mb={2}>{t('Leads')}</Title>
        <DataTable
          tabs={tabs}
          resourceHook={useLeads}
          searchPlaceholder={t('Search a lead by name')}
          paginationLimit={12}
          mb={6}
        >
          {({ items }) => (
            <GridTable
              templateColumns={[
                'minmax(65px,auto) minmax(140px, 1fr) minmax(140px, 1fr) auto auto',
              ]}
              minWidth="650px"
            >
              {items.map(item => (
                <ListItem key={item.id} data={item} />
              ))}
            </GridTable>
          )}
        </DataTable>
      </Container>
    </LayoutSideNavbar>
  )
}

Leads.propTypes = {}

export default Leads
