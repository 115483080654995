import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import { RequestAccess } from 'components/RequestAccess'
import { useTranslation } from 'react-i18next'
import requestAccessImage from './assets/plans.png'

function PlansRequestAccess() {
  const { t } = useTranslation()
  return (
    <LayoutSideNavbar>
      <Container bg="white" maxWidth="390px">
        <RequestAccess
          image={requestAccessImage}
          title={t('Sign patients up to plans')}
          description={t(
            'Plans is a premium feature. Reach out to the Tabeo Sales team to get started!'
          )}
          href="https://calendly.com/tabeo_introduction/tabeo-plans-portal"
        />
      </Container>
    </LayoutSideNavbar>
  )
}

export default PlansRequestAccess
